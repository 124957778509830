<template>
    <div class="c_content_container culture">
		<!-- 상단배너 START -->
		<section class="c_section c_banner banner_culture">
			<div class="c_inner">
				<div class="img_wrapper culture">
					<div class="icon_wrapper">
						<img src="@/assets/images/banner/icon_culture.png" alt="아이콘" class="icon" />
					</div>
				</div>
			</div>
			<div class="c_inner">
				<div class="page_text">
					<h2 v-textMotion class="text_large">
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span>{{ $t("Work Bf 01") }}</span>
						</span><br />
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span>{{ $t("Work Bf 02") }}</span>
						</span><br />
						<span class="textMotion_target">
							<span class="textMotion_target"><span class="text_deco"></span><span class="text_deco">{{
								$t("Work Bf 03") }}</span>
								{{ $t("Work Bf 04") }}</span>
						</span>
					</h2>
				</div>
			</div>
		</section>
		<!-- 상단배너 END -->

		<!-- 컨텐츠 START -->
		<section class="c_section culture_content">
			<div class="c_inner">
				<ul>
					<!--수평적 호칭-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_01.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Horizontal Org Cult') }}</span></span></h2>
								<p>
									<pre>{{ $t('Horizontal Org Cult Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--상호 간 존중-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_02.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Mutual Respect') }}</span></span></h2>
								<p>
									<pre>{{ $t('Mutual Respect Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--워라밸-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_03.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Work Lift Bal') }}</span></span></h2>
								<p>
									<pre>{{ $t('Work Lift Bal Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--의사표현-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_04.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Expression') }}</span></span></h2>
								<p>
									<pre>{{ $t('Expression Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--주도적 개선-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_05.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Initiative Improvement') }}</span></span></h2>
								<p>
									<pre>{{ $t('Initiative Improvement Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--책임-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_06.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Responsibility') }}</span></span></h2>
								<p>
									<pre>{{ $t('Responsibility Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--휴식-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_07.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Pto') }}</span></span></h2>
								<p>
									<pre>{{ $t('Pto Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>

					<!--탄력근무제-->
					<li class="skewAnimation">
						<div v-imageMotion="{rootMargin:'0% 0px 0%'}" class="img_wrapper">
							<div class="img_holder"></div>
						</div>
						<div class="text_wrapper">
							<div class="icon_wrapper">
								<img src="@/assets/images/culture/icon_culture_08.png" alt="아이콘" />
							</div>
							<div v-fadein:reverse class="text">
								<h2 v-textMotion class="text_large"><span class="textMotion_target"><span class="text_deco">{{ $t('Flexible Working Hrs') }}</span></span></h2>
								<p>
									<pre>{{ $t('Flexible Working Hrs Txt') }}</pre>
								</p>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>

		<!-- 컨텐츠 END -->
	</div>
</template>

<script>
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { nextTick } from 'vue'
export default {
	setup(){
		let windowSize = window.innerWidth
		let requestID = ""
		const setWindowSize=()=>{
			windowSize = window.innerWidth
		}

		onMounted(()=>{
			window.addEventListener('resize', setWindowSize)
			nextTick(() => {
			const skewAnimation = document.querySelectorAll('.skewAnimation');

			let currentPos = window.scrollY;

			const update = () => {
					const newPos = window.scrollY;
					const diff = newPos - currentPos;
					const speed = diff * 0.1;
					if(newPos != 0 && currentPos !=0 && windowSize>=768){
						skewAnimation.forEach(el => {
							el.style.transform = `skewY(${ speed }deg)`;
						});
					}
					currentPos = newPos;
					requestID = requestAnimationFrame(update);
				}
				update();
			})
		})

		onUnmounted(()=>{
			cancelAnimationFrame(requestID);
			window.removeEventListener('resize', setWindowSize)
		} )

		return {windowSize, setWindowSize}
	},
}
</script>

<style scoped src="@/assets/css/culture.css"></style>